import http from '../httpConfig';

const medicalService = (config) => {
  const headers = config;
  const endpoint = '/medical_service?uf=SP&itensPerPage=10&page=1&order[ms.name]=ASC';

  const get = (searchText) => new Promise((resolve, reject) => {
    const url = searchText
      ? `${endpoint}&filterAndLike[ms.name]=${searchText}`
      : endpoint;

    http.get(url, headers)
      .then((res) => {
        const { data } = res.data;
        resolve(data.map((item) => ({
          id: item.id,
          name: item.name,
        })));
      })
      .catch(reject);
  });

  return {
    get,
  };
};

export default medicalService;
