<template>
  <section class="user-section">
    <h1 class="text-secondary">
      Histórico Médico
    </h1>

    <medical-history-section
      v-for="section in sections"
      :key="`medical-history-${section.component}`"
    >
      <template v-slot:title>
        {{ section.title }}

        <template v-if="section.tooltip">
          <tooltip :text="section.tooltip" />
        </template>
      </template>

      <template v-slot:content>
        <component
          :is="section.component"
          :is-past="true"
        />
      </template>
    </medical-history-section>
  </section>
</template>

<script>
import MedicalHistorySection from '@/components/user/medicalHistory/medicalHistorySection.vue';
import appointments from '@/components/user/medicalHistory/appointments.vue';
import medicalFiles from '@/components/user/medicalHistory/medicalFiles.vue';
import exams from '@/components/user/medicalHistory/exams.vue';
import prescriptions from '@/components/user/medicalHistory/prescriptions.vue';
import sickNotes from '@/components/user/medicalHistory/sickNotes.vue';
import Tooltip from '@/components/ui/Tooltip.vue';

export default {
  name: 'UserMedicalHistory',

  components: {
    MedicalHistorySection,
    appointments,
    medicalFiles,
    exams,
    prescriptions,
    sickNotes,
    Tooltip,
  },

  data() {
    return {
      sections: [
        {
          title: 'Consultas',
          component: 'appointments',
        },
        {
          title: 'Meus Documentos',
          component: 'medicalFiles',
          tooltip: `Nesta seção você poderá armazenar
            o histórico de exames anteriores, como PDFs
            e fotos de exames, bem como outras informações
            que queira compartilhar com os médicos em seus
            atendimentos.`,
        },
        {
          title: 'Pedidos de exame',
          component: 'exams',
        },
        {
          title: 'Receitas',
          component: 'prescriptions',
        },
        {
          title: 'Atestados',
          component: 'sickNotes',
        },
      ],
    };
  },
};
</script>
