<template>
  <div
    class="tooltip"
    @mouseenter="showTooltip = true"
    @mouseleave="showTooltip = false"
  >
    <icon
      name="info"
      color="neutral"
      class="tooltip__icon"
    />

    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="showTooltip"
        class="tooltip__text"
      >
        {{ text }}
      </div>
    </transition>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon.vue';

export default {
  name: 'Tooltip',

  components: {
    Icon,
  },

  data() {
    return {
      showTooltip: false,
    };
  },

  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip {
  @apply flex items-start justify-end inline-flex;
  cursor: pointer;
  height: 1.5rem;
  margin-left: 0.25rem;
  position: relative;
  width: 1rem;

  &__icon {
    height: 0.9rem;
    width: 0.9rem;
  }

  &__text {
    @apply text-xs p-2 rounded shadow-md;
    background: rgba(0, 0, 0, 0.5);
    bottom: 100%;
    color: #ffffff;
    left: 100%;
    max-width: 21rem;
    position: absolute;
    text-transform: none;
    width: 21rem;
  }
}
</style>
