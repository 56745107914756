import http from '../httpConfig';

const email = (headers) => {
  const sendMedicalDocument = (id, type) => new Promise((resolve, reject) => {
    http.post(`/user/${type}/${id}/send_email`, null, headers)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });

  return {
    sendMedicalDocument,
  };
};

export default email;
