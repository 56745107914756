<template>
  <section class="account-section">
    <header
      class="account-section__header"
      @click="collapse"
    >
      <h2
        class="account-section__title cursor-pointer w-full font-text font-normal"
        :class="!isCollapsed ? 'text-secondary' : 'text-primary'"
      >
        <slot name="title" />
      </h2>

      <i
        :class="{ 'account-section__caret--up': !isCollapsed }"
        class="icon icon-caret account-section__caret"
      />
    </header>

    <transition
      name="fade"
      mode="out-in"
    >
      <article
        v-if="!isCollapsed"
        class="account-section__content"
      >
        <slot name="content" />
      </article>
    </transition>
  </section>
</template>

<script>
export default {
  name: 'MedicalHistorySection',

  data() {
    return {
      isCollapsed: true,
      color: null,
    };
  },

  computed: {
    returnBg() {
      if (this.color) {
        return !this.isCollapsed ? this.color : 'background-color: #fff';
      }

      return 'background-color: #fff';
    },
  },

  created() {
    const color = getComputedStyle(document.body).getPropertyValue('--color-secondary');

    // Color is hexadecimal
    if (/#/gi.test(color)) {
      const colorRgb = this.hexToRgb(color.trim());
      this.color = `background-color: rgba(${colorRgb.r}, ${colorRgb.g}, ${colorRgb.b}, 0.1)`;
    } else {
      // Color is RGB
      const match = color.match(/\d+/g);
      this.color = `background-color: rgba(${match.join(', ')}, 0.1)`;
    }
  },

  methods: {
    collapse() {
      this.isCollapsed = !this.isCollapsed;
    },

    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
        : { r: 255, g: 255, b: 255 };
    },
  },
};
</script>
