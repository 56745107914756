<template>
  <article class="receipt">
    <h3
      class="receipt__title"
      v-if="!data"
    >
      <strong>Recibo</strong>
    </h3>

    <dl class="receipt__items">
      <template v-for="(field, $index) of getData">
        <dt
          class="receipt__term"
          :key="`dt_${$index}`"
        >
          {{ field.label }}:
        </dt>

        <dd
          class="receipt__info"
          :key="`dd_${$index}`"
          :class="$index === '_payment' ? 'pre' : ''"
        >
          {{ field.label === 'Valor' ? 'R$' : '' }} {{ showValue(field) }}
        </dd>
      </template>
    </dl>
  </article>
</template>

<script>
export default {
  props: {
    medicalDocument: {
      type: Object,
      required: true,
    },
    patient: {
      type: Object,
      required: true,
    },
    doctor: {
      type: Object,
      required: true,
    },
    data: {
      type: String,
      default: null,
    },
  },

  computed: {
    getData() {
      if (this.medicalDocument) {
        const { data } = this.medicalDocument;
        delete data['patient.name'];
        return data;
      }
      return this.data;
    },
  },
  methods: {
    showValue(field) {
      return field.filter
        ? this.$options.filters[field.filter](field.value)
        : field.value;
    },
  },
};
</script>
