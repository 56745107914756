import http from '../httpConfig';

const doctorOffice = (headers) => {
  const fetch = (id) => new Promise((resolve, reject) => {
    http.get(`/doctor_office/${id}`, headers)
      .then((res) => {
        resolve(res.data);
      })
      .catch(() => reject());
  });

  return {
    fetch,
  };
};

export default doctorOffice;
