<template>
  <div>
    <document-viewer
      v-if="ui.modalActive"
      :medical-document="selectedPrescription"
      type="prescription"
      @cancel="ui.modalActive = false"
    />

    <transition name="fade">
      <ul
        v-if="ui.isLoaded"
        class="flex flex-wrap overflow-auto"
        style="max-height: 21em"
      >
        <template v-if="prescriptionList.length === 0">
          <li>
            <p>
              Não há prescrições para exibir.
            </p>
          </li>
        </template>

        <template v-else>
          <li
            class="lg:w-6/12 pr-4 pb-4 "
            v-for="prescription of prescriptionList"
            :key="prescription.id"
          >
            <button
              :disabled="ui.isLoading"
              @click="fetch(prescription.id)"
              class="rounded-xl bg-white shadow-md p-4 text-primary cursor-pointer text-left flex"
            >
              <ui-icon
                color="primary"
                class="w-3/12 md:w-2/12 p-3 mb-2"
                name="medicamento"
              />

              <div class="m-2">
                <h4 class="mb-0">
                  {{ prescription.agenda.doctor.display_name }}
                </h4>

                <small class="text-black font-bold">
                  {{ getSpecialtyName(prescription.agenda['medical_specialty']) }}
                </small>

                <p class="mt-2 text-sm">
                  {{ prescription.created_at | toDate }}
                </p>
              </div>
            </button>
          </li>
        </template>
      </ul>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { format, parseISO } from 'date-fns';
import prescriptions from '@/api/user/prescriptions';
import UiIcon from '@/components/ui/Icon.vue';
import DocumentViewer from '@/components/modals/MedicalDocumentView/index.vue';

export default {
  name: 'MedicalHistoryprescriptions',

  filters: {
    toDate(v) {
      return format(parseISO(v), 'dd/MM/yyyy');
    },
  },

  components: {
    UiIcon,
    DocumentViewer,
  },

  data() {
    return {
      prescriptions: null,
      prescriptionList: [],
      pagination: {},
      selectedPrescription: null,
      ui: {
        isLoaded: false,
        isLoading: false,
        modalActive: false,
      },
    };
  },

  computed: {
    ...mapGetters('user', ['headers']),
  },

  mounted() {
    this.prescriptions = prescriptions(this.headers);
    this.list();
  },

  methods: {
    ...mapActions('ui', ['toggleProgressBar']),

    list() {
      this.toggleProgressBar();
      this.ui.isLoaded = false;

      this.prescriptions
        .list(['order[p.createdAt]=DESC'])
        .then((res) => {
          this.prescriptionList.push(...res.data);
          this.pagination = res.pagination;
        })
        .finally(() => {
          this.ui.isLoaded = true;
          this.toggleProgressBar(false);
        });
    },

    fetch(id) {
      this.ui.isLoading = true;
      this.ui.modalActive = false;
      this.toggleProgressBar();

      this.prescriptions
        .fetch(id)
        .then((res) => {
          this.selectedPrescription = res;
          this.ui.modalActive = true;
        })
        .finally(() => {
          this.ui.isLoading = false;
          this.toggleProgressBar(false);
        });
    },

    getSpecialtyName(specialty = null) {
      if (specialty && 'name' in specialty) {
        return specialty.name.toLowerCase();
      }

      return '';
    },
  },
};
</script>
