import http from '../httpConfig';

const medicalFile = (config) => {
  const headers = config;
  const endpoint = '/user/medical_exam';

  const getAll = (limit = 10) => new Promise((resolve, reject) => {
    const url = `${endpoint}?order[ms.createdAt]=ASC&page=1&itensPerPage=${limit}`;

    http.get(url, headers)
      .then((res) => resolve(res.data.data))
      .catch((err) => reject(err.response.data));
  });

  const get = (id = null) => new Promise((resolve, reject) => {
    const url = `${endpoint}${id ? `/${id}` : ''}`;

    http.get(url, headers)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response.data));
  });

  const add = (formData) => new Promise((resolve, reject) => {
    if (formData) {
      const localHeaders = { ...headers };
      localHeaders.headers['Content-Type'] = 'application/x-www-form-urlencoded';

      http.post(
        endpoint,
        formData,
        { headers: localHeaders.headers },
      )
        .then((res) => resolve(res.data))
        .catch((err) => reject(err.response.data));

      return;
    }

    reject();
  });

  const remove = (id = null) => new Promise((resolve, reject) => {
    if (id) {
      const url = `${endpoint}/${id}`;

      http.delete(url, headers)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err.response.data));

      return;
    }

    reject();
  });

  const show = (id = null, contentType) => new Promise((resolve, reject) => {
    if (id) {
      const url = `/s3/file/medical_exam/${id}/exam_file`;

      const localHeaders = { ...headers };
      localHeaders.headers['Content-Type'] = contentType;

      http.get(url, { headers: localHeaders.headers, responseType: 'arraybuffer' })
        .then((res) => {
          resolve(Buffer.from(res.data, 'base64'));
        })
        .catch((err) => reject(err.response.data));

      return;
    }

    reject();
  });

  return {
    getAll,
    get,
    add,
    remove,
    show,
  };
};

export default medicalFile;
