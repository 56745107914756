<template>
  <div>
    <DocumentViewer
      v-if="ui.modalActive"
      :medical-document="selectedExam"
      type="exam_request"
      @cancel="ui.modalActive = false"
    />

    <transition name="fade">
      <ul
        v-if="ui.isLoaded"
        class="flex flex-wrap overflow-auto"
        style="max-height: 21em"
      >
        <template v-if="examList.length === 0">
          <li>
            <p>
              Não há exames para exibir.
            </p>
          </li>
        </template>

        <template v-else>
          <li
            v-for="exam of examList"
            :key="exam.id"
            class="lg:w-6/12 pr-4 pb-4 self-stretch"
          >
            <button
              :disabled="ui.isLoading"
              class="
                rounded-xl
                bg-white shadow-md p-4 text-primary cursor-pointer
                text-left flex self-stretch h-full items-start
              "
              @click="fetch(exam.id)"
            >
              <ui-icon
                color="primary"
                class="w-3/12 md:w-2/12 p-3 mb-2 block"
                style="width: 25%"
                name="medical_test"
              />

              <div
                style="max-width: 70%"
                class="m-2"
              >
                <h4>
                  {{ exam['medical_service'].name }}
                </h4>

                <small class="mt-2 text-sm text-black">
                  {{ exam.created_at | toDate }}
                </small>
              </div>
            </button>
          </li>
        </template>
      </ul>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { format, parseISO } from 'date-fns';
import exams from '@/api/user/exams';
import UiIcon from '@/components/ui/Icon.vue';
import DocumentViewer from '@/components/modals/MedicalDocumentView/index.vue';

export default {
  name: 'MedicalHistoryExams',

  filters: {
    toDate(v) {
      return format(parseISO(v), 'dd/MM/yyyy');
    },
  },

  components: {
    UiIcon,
    DocumentViewer,
  },

  data() {
    return {
      exams: null,
      examList: [],
      pagination: {},
      selectedExam: null,
      ui: {
        isLoaded: false,
        isLoading: false,
        modalActive: false,
      },
    };
  },

  computed: {
    ...mapGetters('user', ['headers']),
  },

  mounted() {
    this.exams = exams(this.headers);
    this.list();
  },

  methods: {
    ...mapActions('ui', ['toggleProgressBar']),

    list() {
      this.toggleProgressBar();
      this.ui.isLoaded = false;

      this.exams
        .list(['order[er.createdAt]=DESC'])
        .then((res) => {
          this.examList.push(...res.data);
          this.pagination = res.pagination;
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.ui.isLoaded = true;
          this.toggleProgressBar(false);
        });
    },

    fetch(id) {
      this.ui.isLoading = true;
      this.ui.modalActive = false;

      this.toggleProgressBar();

      this.exams
        .fetch(id)
        .then((res) => {
          this.selectedExam = res;
          this.ui.modalActive = true;
        })
        .finally(() => {
          this.ui.isLoading = false;
          this.toggleProgressBar(false);
        });
    },
  },
};
</script>
