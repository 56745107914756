<template>
  <transition
    name="fade"
    mode="in-out"
  >
    <modal
      size="modal-medical-file"
      @close="$emit('close')"
    >
      <template v-slot:title>
        Adicionar documento médico
      </template>

      <template v-slot:body>
        <div class="pt-4">
          <ui-form
            class="profile__form !mb-0"
            :form-data.sync="formData"
            :sending="ui.isLoading"
            @valid="ui.isFormValid = $event"
          />

          <ui-button
            :disabled="
              ui.isLoading || !ui.isFormValid
            "
            width="auto"
            class="ml-auto w-64 mt-4 !h-8"
            style="height: 2.5rem"
            label="Salvar documento"
            @click="submit"
          />
        </div>
      </template>

      <transition
        name="fade"
        mode="out-in"
      >
        <loader v-if="ui.isLoading" />
      </transition>
    </modal>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import medicalService from '@/api/general/medicalService';
import medicalFiles from '@/api/user/medicalFiles';
import formData from '@/data/user/medicalFile';
import handleError from '@/mixins/handleError';
import Modal from '@/components/ui/Modal.vue';
import UiForm from '@/components/ui/form/_form.vue';
import UiButton from '@/components/ui/Button.vue';
import Loader from '@/components/ui/Loader.vue';

export default {
  name: 'MedicalFilesForm',

  mixins: [handleError],

  props: {
    file: {
      type: File,
      required: false,
      default: () => ({}),
    },
  },

  components: {
    Modal,
    UiForm,
    UiButton,
    Loader,
  },

  data() {
    return {
      formData,
      medicalService: null,
      medicalFiles: null,
      ui: {
        isLoading: false,
        isFormValid: false,
      },
    };
  },

  computed: {
    ...mapGetters('user', ['headers']),
  },

  created() {
    this.medicalService = medicalService(this.headers);
    this.formData.medicalService.callback = this.medicalService.get;

    this.medicalFiles = medicalFiles(this.headers);
  },

  beforeDestroy() {
    Object.keys(this.formData).forEach((key) => {
      this.formData[key].value = null;
    });
  },

  methods: {
    ...mapActions('ui', ['toggleProgressBar', 'openModalDialog', 'closeModalDialog']),

    submit() {
      this.ui.isLoading = true;
      this.toggleProgressBar();

      const [day, month, year] = this.formData.realizationDate.value.split('/');
      const realizationDate = `${year}-${month}-${day}`;

      const formData = new FormData();
      formData.append('user_medical_exam[laboratory]', this.formData.laboratory.value);
      formData.append('user_medical_exam[realizationDate]', realizationDate);
      formData.append('user_medical_exam[medicalService]', this.formData.medicalService.value);
      formData.append('user_medical_exam[examFile][file]', this.file);

      this.medicalFiles
        .add(formData)
        .then(() => {
          this.$emit('created', true);
          this.$emit('close', true);

          this.openModalDialog({
            type: 'alert',
            title: 'Sucesso',
            text: 'Seu exame foi salvo com sucesso.',
            confirmText: 'Fechar',
            size: 'sm',
            fn: this.closeModalDialog,
          });
        })
        .catch((err) => this.handleError(err))
        .finally(() => {
          this.ui.isLoading = false;
          this.toggleProgressBar(false);
        });
    },
  },
};
</script>

<style>
.modal-medical-file {
  max-width: 28rem !important;
}
</style>
