<style lang="scss" scoped>
.modal-body {
  overflow: initial;
}
h1.icon {
  font-size: 2em;
  float: right;
  cursor: pointer
}
</style>

<template>
  <div>
    <p class="text-center mb-8">
      RECEITA MÉDICA - <strong>{{ medicalDocument.created_at | toDate }}</strong>
    </p>
    <ul>
      <li
        class="break"
        v-for="(prescriptionMedicament, $index) of medicalDocument.prescription_medicaments"
        v-show="prescriptionMedicament.id"
        :key="$index"
      >
        <strong>{{ prescriptionMedicament.medicament.name }}
          ({{ returnActivePrinciples(prescriptionMedicament.medicament.active_principles) }}) -
          {{ prescriptionMedicament.medicament.label }}
        </strong><br><br>
        <ul class="list-unstyled">
          <li>
            &emsp;&emsp;
            Quantidade:
            {{ prescriptionMedicament.quantity }}
            {{ prescriptionMedicament['medicament_prescription_quantity_unity'] }}
            {{ prescriptionMedicament.quantity > 1 ? 's' : '' }}
          </li>
          <li>&emsp;&emsp;Posologia: {{ prescriptionMedicament.posology }}</li>
          <li v-if="prescriptionMedicament.note">
            &emsp;&emsp;Observações: {{ prescriptionMedicament.note }}
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns';

export default {
  props: {
    medicalDocument: {
      type: Object,
      required: true,
    },
  },
  filters: {
    toDate(v) {
      return format(parseISO(v), 'dd/MM/yyyy');
    },
  },
  methods: {
    returnActivePrinciples(aps) {
      const arr = [];
      for (const principle of aps) {
        arr.push(principle.name);
      }
      return arr.join(', ');
    },
  },
};
</script>
