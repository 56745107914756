<template>
  <div class="medical-files">
    <div class="bg-white">
      <droppable
        @success="loadFile($event)"
        :form-data="formData"
      />
    </div>

    <medical-files-list
      ref="medical-file-list"
      :maximum="maximum"
    />

    <transition
      name="fade"
      mode="out-in"
    >
      <medical-files-form
        v-if="ui.showMedicalFilesForm"
        :file.sync="formData.medicalFile.value"
        @created="$refs['medical-file-list'].init"
        @close="ui.showMedicalFilesForm = false"
      />
    </transition>
  </div>
</template>

<script>
import Droppable from '@/components/ui/form/Droppable.vue';
import MedicalFilesForm from '@/components/user/account/medicalFilesForm.vue';
import MedicalFilesList from '@/components/user/account/medicalFilesList.vue';

export default {
  name: 'AccountMedicalFiles',

  components: {
    Droppable,
    MedicalFilesForm,
    MedicalFilesList,
  },

  props: {
    maximum: {
      type: Number,
      required: false,
      default: () => 4,
    },
  },

  data() {
    return {
      formData: {
        medicalFile: {
          label: 'Documento médico',
          value: null,
          type: 'file',
          required: true,
          exts: ['.jpg', '.jpeg', '.png', '.pdf'],
        },
      },
      ui: {
        isLoading: false,
        showMedicalFilesForm: false,
        reloadList: 0,
      },
    };
  },

  methods: {
    loadFile(file) {
      this.ui.showMedicalFilesForm = true;
      this.$set(this.formData.medicalFile, 'value', file);
    },
  },
};
</script>

<style lang="scss" scoped>
.medical-files {
  @apply bg-transparent;
}
</style>
