import { parse, isBefore, isValid } from 'date-fns';

const formData = {
  laboratory: {
    name: 'laboratory',
    type: 'text',
    label: 'Nome do laboratório',
    placeholder: 'Nome do laboratório',
    lazy: true,
    disabled: false,
    value: null,
    required: true,
  },
  realizationDate: {
    name: 'realizationDate',
    type: 'text',
    label: 'Data de realização',
    mask: ['##/##/####'],
    placeholder: 'dd/mm/aaaa',
    lazy: true,
    disabled: false,
    value: null,
    required: true,
    isValid: (value = null) => {
      if (value && value.length === 10) {
        const newDate = parse(
          value,
          'dd/LL/yyyy',
          new Date(),
        );

        return isValid(newDate) && isBefore(newDate, new Date());
      }

      return false;
    },
  },
  medicalService: {
    name: 'medicalService',
    type: 'autocomplete',
    label: 'Nome do exame',
    placeholder: 'Digite o nome do exame para buscar',
    lazy: true,
    disabled: false,
    value: null,
    required: true,
  },
};

export default formData;
