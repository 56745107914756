<template>
  <modal @close="$emit('close', true)">
    <template v-slot:title>
      Documento médico - {{ file.name }}
    </template>

    <template v-slot:body>
      <div
        v-if="ui.hasLoaded"
        class="mt-8"
      >
        <object
          v-if="/pdf$/gi.test(type)"
          :type="type"
          :data="fileData"
          class="file-viewer js-file-viewer"
          style="height: 100vh"
        />

        <img
          v-else
          :src="fileData"
          class="file-viewer"
        >
      </div>

      <transition
        name="fade"
        mode="out-in"
      >
        <loader v-if="ui.isLoading" />
      </transition>
    </template>
  </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import handleError from '@/mixins/handleError';
import medicalFiles from '@/api/user/medicalFiles';
import Modal from '@/components/ui/Modal.vue';
import Loader from '@/components/ui/Loader.vue';

export default {
  name: 'MedicalFileViewer',

  mixins: [handleError],

  components: {
    Modal,
    Loader,
  },

  props: {
    file: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      medicalFiles: null,
      fileData: null,
      ui: {
        isLoading: false,
        hasLoaded: false,
      },
    };
  },

  computed: {
    ...mapGetters('user', ['headers']),

    type() {
      if (!/\.pdf$/gi.test(this.file.file)) {
        return `image/${this.file.file.match(/[^.]*$/gi)[0]}`;
      }

      return 'application/pdf';
    },
  },

  created() {
    this.medicalFiles = medicalFiles(this.headers);

    this.ui.isLoading = true;
    this.toggleProgressBar();

    this.medicalFiles
      .show(this.file.id, this.type)
      .then((rawData) => {
        const blob = new Blob([rawData], { type: this.type });
        this.fileData = URL.createObjectURL(blob);
        this.ui.hasLoaded = true;
      })
      .catch((err) => this.handleError(err))
      .finally(() => {
        this.ui.isLoading = false;
        this.toggleProgressBar(false);
      });
  },

  methods: {
    ...mapActions('ui', ['toggleProgressBar']),
  },
};
</script>

<style lang="scss" scoped>
.file-viewer {
  @apply my-4 h-full w-full;
  max-height: 75vh;
  object-fit: contain;
}
</style>
