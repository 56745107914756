import http from '../httpConfig';

const exams = (headers) => {
  const list = (query = []) => new Promise((resolve, reject) => {
    http.get(`/user/exam_request?${query.join('&')}`, headers)
      .then((res) => {
        resolve(res.data);
      })
      .catch(() => reject());
  });

  const fetch = (id) => new Promise((resolve, reject) => {
    http.get(`/user/exam_request/${id}`, headers)
      .then((res) => {
        resolve(res.data);
      })
      .catch(() => reject());
  });

  return {
    list,
    fetch,
  };
};

export default exams;
