<template>
  <section class="medical-files">
    <h3 class="medical-files__title">
      Documentos recentes
    </h3>

    <transition
      name="fade"
      mode="out-in"
    >
      <ul
        v-if="ui.hasLoaded && medicalFiles.list.length > 0"
        :class="{ 'medical-files__list--disabled': ui.hasLoaded && ui.isLoading }"
        class="medical-files__list"
      >
        <li
          v-for="file in medicalFiles.list"
          :key="`medical-file-${file.id}`"
          class="medical-files__file"
          @mouseenter="file.showDelete = true"
          @mouseleave="file.showDelete = false"
        >
          <div
            :class="{ 'medical-files__icon--pdf': getIcon(file.file) === 'adobe' }"
            class="medical-files__icon"
            @click="showFile(file)"
          >
            <icon
              :name="getIcon(file.file)"
              color="white"
              fill="white"
              class="mt-1 m-0 p-0"
              style="width: 1.5rem; height: 1.5rem; max-height: 2rem;"
            />
          </div>

          <div class="medical-files__content">
            <h5 class="medical-files__name">
              {{ file.name }}
            </h5>

            <p class="medical-files__date">
              {{ formatDate(file.created) }}
            </p>
          </div>

          <template
            name="fade"
            mode="in-out"
          >
            <i
              v-if="file.showDelete"
              class="medical-files__delete icon icon-cross"
              @click="confirmRemove(file.id)"
            />
          </template>
        </li>
      </ul>
    </transition>

    <transition name="fade">
      <p
        v-if="ui.hasLoaded && medicalFiles.list.length === 0"
        class="text-sm pb-2"
      >
        Nenhum documento médico cadastrado.
      </p>
    </transition>

    <transition
      name="fade"
      mode="out-in"
    >
      <medical-files-viewer
        v-if="ui.showFile"
        :file.sync="whichFile"
        @close="ui.showFile = false"
      />
    </transition>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { parseISO, format } from 'date-fns';
import medicalFiles from '@/api/user/medicalFiles';
import handleError from '@/mixins/handleError';
import Icon from '@/components/ui/Icon.vue';
import MedicalFilesViewer from '@/components/user/medicalHistory/medicalFilesViewer.vue';

export default {
  name: 'MedicalFilesList',

  mixins: [handleError],

  components: {
    Icon,
    MedicalFilesViewer,
  },

  props: {
    maximum: {
      type: Number,
      required: false,
      default: () => 4,
    },

    reload: {
      type: Number,
      required: false,
      default: () => 0,
    },
  },

  data() {
    return {
      medicalFiles: {
        api: null,
        list: [],
      },
      ui: {
        isLoading: false,
        hasLoaded: false,
        showFile: false,
      },
      whichFile: null,
    };
  },

  computed: {
    ...mapGetters('user', ['headers']),
  },

  created() {
    this.medicalFiles.api = medicalFiles(this.headers);

    this.init();
  },

  watch: {
    reload: {
      deep: false,
      immediate: false,
      handler(v) {
        if (v > 0) {
          this.init();
        }
      },
    },
  },

  methods: {
    ...mapActions('ui', ['toggleProgressBar', 'openModalDialog', 'closeModalDialog']),

    init() {
      this.ui.hasLoaded = false;
      this.ui.isLoading = true;
      this.toggleProgressBar();

      this.medicalFiles.api
        .getAll(this.maximum)
        .then((data) => {
          this.medicalFiles.list = data.map((item) => ({
            id: item.id,
            name: item['medical_service'].name,
            laboratory: item.laboratory,
            file: item.exam,
            created: item['created_at'],
            showDelete: false,
          }));
        })
        .catch((err) => this.handleError(err))
        .finally(() => {
          this.ui.isLoading = false;
          this.ui.hasLoaded = true;
          this.toggleProgressBar(false);
        });
    },

    formatDate(dateStr) {
      return format(parseISO(dateStr), 'dd/MM/yyyy');
    },

    getIcon(file) {
      return /\.pdf$/gi.test(file) ? 'adobe' : 'image';
    },

    confirmRemove(id = null) {
      if (id) {
        this.openModalDialog({
          type: 'confirmation',
          title: 'Confirmação',
          text: 'Deseja apagar este documento médico? Esta ação não poderá ser desfeita.',
          cancelText: 'Cancelar',
          confirmText: 'Confirmar',
          fn: () => {
            this.removeFile(id);
            this.closeModalDialog();
          },
        });
      }
    },

    removeFile(id) {
      this.ui.isLoading = true;
      this.toggleProgressBar();

      this.medicalFiles.api
        .remove(id)
        .then(() => {
          this.openModalDialog({
            type: 'alert',
            title: 'Sucesso',
            text: 'O documento médico foi removido com sucesso.',
            confirmText: 'Fechar',
            fn: () => {
              this.closeModalDialog();
              this.init();
            },
          });
        })
        .catch((err) => this.handleError(err))
        .finally(() => {
          this.ui.isLoading = false;
          this.toggleProgressBar(false);
        });
    },

    showFile(file) {
      this.whichFile = file;
      this.ui.showFile = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.medical-files {
  &__title {
    @apply mt-2 text-sm text-primary;
  }

  &__list {
    @apply flex flex-row items-center content-start justify-between flex-wrap;

    &--disabled {
      @apply pointer-events-none;
      opacity: 0.5;
    }
  }

  &__file {
    @apply w-full;
    @apply bg-gray-100;
    @apply mb-2 p-2;
    @apply self-stretch;
    @apply relative;
    @apply flex flex-row items-start content-start justify-start;

    @screen md {
      width: 49%;
    }
  }

  &__content {
    @apply ml-2;
  }

  &__name {
    @apply text-sm text-gray-400 truncate;
    max-width: 30ch;

    @screen md {
      max-width: 20ch;
    }
  }

  &__date {
    @apply text-xs text-gray-300;
  }

  &__delete {
    @apply absolute text-xs cursor-pointer text-red;
    top: 4px;
    right: 4px;
  }

  &__icon {
    @apply relative rounded rounded-md self-stretch;
    @apply flex items-center justify-center;
    @apply cursor-pointer;
    background-color: #4888E9;
    width: 2rem;

    &:after {
      @apply block absolute;
      border-color: #2965C1 var(--color-gray-100);
      border-radius: 1px;
      border-style: solid;
      border-width: 0 10px 10px 0;
      content: '';
      right: 0;
      top: 0;
    }

    &--pdf {
      background-color: #E94848;

      &:after {
        border-color: #C53C3C var(--color-gray-100);
      }
    }
  }
}
</style>
