<style lang="scss" scoped>
.modal-body {
  overflow: initial;
}
h1.icon {
  font-size: 2em;
  float: right;
  cursor: pointer
}
.box {
  border: 1px solid #e1e1e1;
  margin: 10px 5px 5px 5px;
  header {
    border-bottom: 1px solid #e1e1e1;
    padding: 5px 0;
    margin-bottom: 5px;
  }
  main {
    display: flex;
    padding: 1em;
    flex-direction: column
  }
}
.line {
  border-bottom: 2px solid #e1e1e1;
  width: 100%;
  min-height: 1em;
  display: block
}
.type{
  display: none;
}
</style>

<template>
  <div>
    <div
      v-for="i in 2"
      :key="`template_${i}`"
    >
      <header>
        <p class="text-center col-12">
          <big><strong>RECEITUÁRIO CONTROLE ESPECIAL</strong></big>
        </p>
        <div class="flex-space_between">
          <div class="box col-5 text-center">
            <header><strong>IDENTIFICAÇÃO DO EMITENTE</strong></header>
            <main>
              <p>
                <big>
                  <strong>{{ doctor.user.gender === 'M' ? 'Dr.' : 'Dra.' }}
                    {{ doctor.user.name }}</strong>
                </big><br>
                <small>{{ doctor['medical_specialties'].map(n => n.name).join(', ') }} &bull;
                  CRM {{ doctor.crm }}</small>
                <small v-if="doctor.address">
                  <br><br>
                  {{ doctor.address.street }}, {{ doctor.address.number }}
                  {{ doctor.address.complement }}
                  <br>
                  {{ doctor.address.city.name }} - {{ doctor.address.uf.name }}<br>
                  {{ doctor.address.cep }}<br>
                </small>
              </p>
            </main>
          </div>
          <p>
            <strong class="type">
              <span v-if="i === 1">
                1&ordf; VIA FARMÁCIA
              </span>
              <span v-if="i === 2">
                2&ordf; VIA PACIENTE
              </span>
            </strong>
          </p>
        </div>
      </header>
      <main>
        <ul class="list-unstyled">
          <li>
            <strong>Paciente:&nbsp;</strong>{{ patient.name }}
          </li>
          <li class="d-flex">
            <strong>Endereço:&nbsp;</strong> <template v-if="getAddress">
              {{ getAddress }}
            </template> <span
              v-else
              class="line"
            />
          </li>
          <li>
            <strong>Receita:&nbsp;</strong>
            <ul class="list-unstyled">
              <li
                class="break"
                v-for="(prescriptionMedicament, $index) of medicalDocument.prescription_medicaments"
                v-show="prescriptionMedicament.id"
                :key="$index"
              >
                &emsp;
                <strong>{{ prescriptionMedicament.medicament.name }}
                  (
                  {{ returnActivePrinciples(prescriptionMedicament.medicament.active_principles) }}
                  ) -
                  {{ prescriptionMedicament.medicament.label }}
                </strong><br>
                <ul class="list-unstyled">
                  <li>
                    &emsp;&emsp;
                    Quantidade:
                    {{ prescriptionMedicament.quantity }}
                    {{ prescriptionMedicament['medicament_prescription_quantity_unity'] }}
                    {{ prescriptionMedicament.quantity > 1 ? 's' : '' }}
                  </li>
                  <li>&emsp;&emsp;Posologia: {{ prescriptionMedicament.posology }}</li>
                  <li v-if="prescriptionMedicament.note">
                    &emsp;&emsp;Observações: {{ prescriptionMedicament.note }}
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <br>
        <div class="flex-space_between text-center">
          <span class="col-6">
            {{ $moment(new Date()).format('DD/MM/YYYY') }}<br>
            <strong>Data</strong>
          </span>
          <span class="col-6">
            ____________________________________________<br>
            <strong>Assinatura do emitente</strong>
          </span>
        </div>
        <br>
      </main>
      <footer
        class="d-flex"
        style="justify-content: center; height: 300px"
      >
        <div class="box col-6 text-center">
          <header><strong>IDENTIFICAÇÃO DO COMPRADOR</strong></header>
          <main>
            <p class="d-flex">
              <strong>Nome: </strong><span class="line" />
            </p>
            <p class="d-flex">
              <strong>Ident.: </strong><span class="line" />
              <strong>Org.&nbsp;emissor: </strong><span class="line" />
            </p>
            <p class="d-flex">
              <strong>End.: </strong><span class="line" />
            </p>
            <p class="d-flex">
              <strong>Cidade: </strong><span class="line" />
              <strong>UF </strong><span
                class="line"
                style="width: 30%"
              />
            </p>
            <p class="d-flex">
              <strong>Telefone: </strong><span class="line" />
            </p>
          </main>
        </div>
        <div class="box col-6 text-center">
          <header><strong>IDENTIFICAÇÃO DO FORNECEDOR</strong></header>
          <p style="margin-top: 40%; white-space: nowrap">
            <strong
              style="white-space: nowrap; border-top: 2px solid #e1e1e1; padding: 0 1em"
            >Assinatura do farmacêutico
            </strong><br><br>
            <strong style="white-space: nowrap">&emsp;DATA &emsp;/&emsp;/&emsp;&emsp;</strong>
          </p>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    medicalDocument: {
      type: Object,
      required: true,
    },
    patient: {
      type: Object,
      required: true,
    },
    doctor: {
      type: Object,
      required: true,
    },
  },

  computed: {
    getAddress() {
      return this.patient.address
        ? `${this.patient.address.street},
        ${this.patient.address.number} ${this.patient.address.complement || ''};
        ${this.patient.address.city.name} - ${this.patient.address.uf.name}`
        : null;
    },
  },
  methods: {
    returnActivePrinciples(aps) {
      const arr = [];
      for (const principle of aps) {
        arr.push(principle.name);
      }
      return arr.join(', ');
    },
  },
};
</script>
