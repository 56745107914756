<template>
  <medical-files :maximum="100" />
</template>

<script>
import medicalFiles from '@/components/user/account/medicalFiles.vue';

export default {
  name: 'MedicalHistoryFiles',

  components: {
    medicalFiles,
  },
};
</script>
