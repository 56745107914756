import http from '../httpConfig';

const signedFile = (headers) => {
  const fetch = (id, type) => new Promise((resolve, reject) => {
    http.get(`${process.env.VUE_APP_BASE_API}/s3/file/${type}/${id}/signed_file`, {
      headers: headers.headers,
      responseType: 'blob',
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });

  return {
    fetch,
  };
};

export default signedFile;
