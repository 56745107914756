<template>
  <div>
    <p class="text-center">
      PEDIDO DE EXAME - <strong>{{ medicalDocument.created_at | toDate }}</strong>
    </p>
    <p>Nome do exame: <strong>{{ medicalDocument.medical_service.name }}</strong></p>
    <p>Observações: <span v-html="medicalDocument.note" /></p>
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns';

export default {
  props: {
    medicalDocument: {
      type: Object,
      required: true,
    },
  },

  filters: {
    toDate(v) {
      return format(parseISO(v), 'dd/MM/yyyy');
    },
  },
};
</script>
