<template>
  <div>
    <p class="text-center">
      <big>ATESTADO - <strong>{{ medicalDocument.date | toDate }}</strong></big>
    </p>
    <p v-html="medicalDocument.note.replace('#CID-X', '')" />
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns';

export default {
  props: {
    medicalDocument: {
      type: Object,
      required: true,
    },
  },
  filters: {
    toDate(v) {
      return format(parseISO(v), 'dd/MM/yyyy');
    },
  },
};
</script>
