<style scoped>
a.print {
  position: absolute;
  right: 2em;
  top: 2em;
  display: flex;
  align-items: center;
}
.page {
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}
.signature {
  height: 60px;
}
</style>

<template>
  <transition name="fade">
    <Modal
      :key="loaded"
      @close="dismiss()"
    >
      <template v-slot:title>
        {{ returnTitle }}
      </template>

      <template
        v-if="loaded"
        v-slot:body
      >
        <div
          v-if="isPdf"
          id="pdf__container"
          class="py-8 border-t border-b border-gray-200 mb-8 mt-4"
        />

        <div
          v-else
          class="py-8 border-t border-b border-gray-200 mb-8 mt-4"
        >
          <div
            v-for="copy of getCopies"
            :key="copy"
            class="page w-full"
            :class="copy === 1 ? '' : 'd-hide'"
          >
            <header class="modal-header">
              <a
                v-if="1===2"
                class="print btn btn-default btn-sm"
                @click="print"
              >
                <UiIcon
                  style="width: 20px"
                  name="imprimir"
                  fill="white"
                />
                &emsp;Imprimir
              </a>

              <img
                :style="{ 'opacity': partner.hasIframe ? 0 : 1 }"
                :alt="partner.name"
                :src="require(`@/assets/img/logo/${partner.logo.header}`)"
              >

              <template v-if="type !== 'medicalPrescriptionSpecial'">
                <br><br>

                <h2>
                  <strong>
                    {{ doctor.user.gender === 'M' ? 'Dr.' : 'Dra.' }}
                    {{ doctor.user.name }}
                  </strong>
                </h2>

                <p class="float-left">
                  <small>
                    {{ medicalSpecialties }}&emsp;&emsp;
                    CRM {{ doctor.crm }}
                  </small>

                  <small v-if="address">
                    {{ address.street }}, {{ address.number }} {{ address.complement }}<br>
                    {{ address.city.name }} - {{ address.uf.name }}<br>
                    {{ address.cep }}<br>
                  </small>
                </p>

                <p
                  v-if="getPatient"
                  class="float-right"
                >
                  Paciente: <strong>{{ getPatient.name }}</strong>&emsp;<br>
                  <small v-if="getPatient.rg">RG {{ getPatient.rg }} <br></small>
                  <small v-if="getPatient.cpf">CPF {{ getPatient.cpf }}</small>
                </p>
              </template>
            </header>

            <br>
            <br>

            <div class="modal-body">
              <component
                :is="returnComponent"
                :patient="getPatient"
                :doctor="doctor"
                :medical-document="medicalDocument"
                @loaded="loaded = $event"
              />
            </div>

            <footer :class="type === 'sick_note' ? 'flex justify-between' : ''">
              <div v-if="type === 'sick_note'">
                <p v-if="medicalDocument['has_display_cid_code']">
                  <br><br>
                  _____________________________________<br>
                  Assinatura do paciente<br>
                </p>

                <p v-else>
                  <br><br>
                  _____________________________________<br>
                  Assinatura do paciente, <br> em anuência à exibição do CID.<br>
                </p>
              </div>

              <template v-if="type !== 'medicalPrescriptionSpecial' && type !== 'receipt'">
                <p
                  v-if="!signature"
                  class="text-right"
                >
                  <br><br>
                  _____________________________________<br>
                  Carimbo e assinatura do médico
                </p>

                <p
                  v-else
                  class="text-right"
                >
                  <img
                    class="signature"
                    :src="medicalAssinatureURL"
                  >
                </p>
              </template>
            </footer>
          </div>
        </div>

        <footer class="flex justify-between">
          <button
            :class="{ 'sm:hidden': type === 'prescription' }"
            class="button button--blank !px-4 button--sm"
            :disabled="isLoading"
            @click="dismiss"
          >
            &lt; Voltar
          </button>

          <router-link
            v-if="type === 'prescription' && partner.hasIntegrationPartners"
            :to="{ name: 'UserPharmacyDiscount' }"
            class="button button--primary button--sm"
          >
            Desconto Farmácia
          </router-link>

          <button
            class="button button--primary button--sm"
            :disabled="isLoading"
            @click="sendMail"
          >
            Enviar por e-mail
          </button>
        </footer>
      </template>
    </Modal>
  </transition>
</template>

<script>
import Modal from '@/components/ui/Modal.vue';
import UiIcon from '@/components/ui/Icon.vue';
import { mapGetters } from 'vuex';
import doctorOffice from '@/api/doctor/doctorOffice';
import signedFile from '@/api/user/signedFile';
import email from '@/api/user/email';
import handleError from '@/mixins/handleError';
import handleSuccess from '@/mixins/handleSuccess';
import prescription from './medicalPrescription.vue';
import prescriptionSpecial from './medicalPrescriptionSpecial.vue';
import sickNote from './sickNote.vue';
import exam from './exam.vue';
import receipt from './receipt.vue';

export default {
  name: 'DocumentViewer',

  props: {
    medicalDocument: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    copies: {
      type: Number,
      default: 1,
    },
  },
  mixins: [handleError, handleSuccess],
  components: {
    UiIcon,
    prescription,
    prescriptionSpecial,
    sickNote,
    exam,
    receipt,
    Modal,
  },
  data() {
    return {
      loaded: false,
      isLoading: false,
      isPdf: false,
      localActive: false,
      doctor: null,
      signature: null,
      address: null,
      cid: null,
    };
  },
  computed: {
    ...mapGetters('partner', ['partner']),

    returnTitle() {
      let str = '';
      switch (this.type) {
        case 'exam_request': str = 'Pedido de exame'; break;
        case 'prescription': str = 'Prescrição médica'; break;
        case 'sick_note': str = 'Atestado'; break;
        default: str = 'Histórico médico';
      }
      return str;
    },

    returnComponent() {
      let str = '';
      switch (this.type) {
        case 'exam_request': str = 'exam'; break;
        case 'prescription': str = 'prescription'; break;
        case 'sick_note': str = 'sickNote'; break;
        default: str = this.type;
      }
      return str;
    },

    getCopies() {
      return this.copies || 1;
    },
    getPatient() {
      return this.patient || this.medicalDocument.agenda.patient || null;
    },
    medicalSpecialties() {
      return this.doctor['medical_specialties'].map((n) => n.name).join(', ');
    },

    medicalAssinatureURL() {
      return `${process.env.ROOT_API}/s3/file/doctor/${this.medicalDocument.agenda.doctor.id}/signature_picture`;
    },
  },
  mounted() {
    this.patient = this.$store.getters['user/user'];
    this.doctorOffice = doctorOffice(this.$store.getters['user/headers']);
    this.doctor = this.medicalDocument.agenda.doctor || this.medicalDocument.doctor;
    this.loaded = false;
    this.localActive = true;
    if (('signed_file' in this.medicalDocument) && this.medicalDocument['signed_file']) {
      this.isPdf = true;
      signedFile(this.$store.getters['user/headers']).fetch(this.medicalDocument.id, this.type)
        .then((res) => this.generatePDFFile(res.data))
        .then((objURL) => this.embedFile(objURL));
      return;
    }
    this.loaded = true;
    if (!this.medicalDocument.agenda.doctor_office.address) {
      this.doctorOffice.fetch(this.medicalDocument.agenda.doctor_office.id).then(
        (res) => {
          if (res) {
            this.address = res.data.address;
          }
        },
      );
    } else {
      this.address = this.medicalDocument.agenda.doctor_office.address;
    }
  },
  methods: {
    dismiss() {
      this.localActive = false;
      this.$emit('cancel', true);
    },
    print() {
      this.$htmlToPaper('print', () => {
        this.$emit('cancel', true);
      });
    },
    generatePDFFile(rawData) {
      this.loaded = true;
      const blob = new Blob([rawData], { type: 'application/pdf' });
      return URL.createObjectURL(blob);
    },

    embedFile(file = null) {
      if (file) {
        const wrapper = document.querySelector('#pdf__container');
        const embed = document.createElement('iframe');
        embed.classList.add('pdf-file');
        embed.style.height = '70vh';
        embed.style.width = '100%';
        embed.setAttribute('src', file);
        wrapper.appendChild(embed);

        return true;
      }

      return false;
    },

    sendMail() {
      this.isLoading = true;
      this.$store.dispatch('ui/toggleProgressBar', true);
      email(this.$store.getters['user/headers']).sendMedicalDocument(this.medicalDocument.id, this.type)
        .then(() => {
          this.$store.dispatch('ui/toggleProgressBar', false);
          this.handleSuccess('O documento foi enviado para o e-mail informado no seu cadastro.');
          this.dismiss();
        }).catch(
          () => {
            this.handleError();
          },
        );
    },

    removeEmbed() {
      const wrapper = document.querySelector('#pdf__container');
      const embed = wrapper.querySelectorAll('embed');
      for (const el of embed) {
        el.remove();
      }
      this.modalType = null;
      this.currentDocument = null;
    },
  },
};
</script>
